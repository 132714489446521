import React from 'react'
import Lottie from 'lottie-react'
import notfound from '../../public/error/notfound.json'

export default function Custom500() {
    return (
        <div className="grid lg:grid-cols-12 h-screen px-4 bg-[#E5E5E5] place-content-center">
            <div className="text-center col-start-5 col-end-9">
                <div>
                    <Lottie animationData={notfound} loop={true} className="h-full" />
                </div>

                <div className='w-full'>
                    <p
                        className="mt-6 text-[48px] font-[590] tracking-tight text-gray-900 sm:text-4xl"
                    >
                        Page Not Found
                        <hr className='bg-gradient-to-r mx-auto from-[#2EBBFF] via-[#FE17FF] to-[#6232EA] h-[5px] w-[7rem] rounded-[22.5px]' />
                    </p>

                    <p className="mt-4 text-[18px] leading-[28px] text-[#010140]">This page is currently unavailable or is not responding, <br /> please come back later</p>
                </div>
            </div>
        </div>
    )
}
{/* <div
className="w-96 h-full p-4  relative text-black sm:h-64" 
>
  <Image alt='page not found' src={'/notfound/boxfly.gif'} height={'375px'} width={'439px'} objectFit='cover' />
</div> */}