import React from "react";
import Err404 from "../components/errorPages/404";
import Err500 from "../components/errorPages/500";
import Error from "next/error";

function ErrorPage({ statusCode }) {
  return statusCode == 404 ? (
    <Err404 />
  ) : statusCode == 500 ? (
    <Err500 />
  ) : (
    <Error />
  );
}

ErrorPage.getInitialProps = ({ res, err, locale }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode, messages: require(`../locales/${locale || "en"}.json`) };
};

export default ErrorPage;
