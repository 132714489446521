import Router from "next/router";
import React from "react";
import CustomBtnComponent from "../../components/btn/buttonCustom";
import oops from "../../public/error/Composition 1.json";
import Lottie from "lottie-react";
import { useTranslations } from "next-intl";

function Error404() {
  const t = useTranslations("page404");
  return (
    <div className="grid-cols-12 responsive mb-20">
      {/* image */}
      <div className="relative h-[280px] sm:h-[340px] mds:h-[478px] mx-auto flex justify-center mds:mt-[45px] col-span-12  ">
        <Lottie animationData={oops} loop={true} className="h-full" />

        <div className="absolute mx-auto bottom-[15px] sm:bottom-[24px] mds:bottom-[39px] flex flex-col items-center ">
          <h3 className="xsm:leading-[37px] mds:leading-[56px] font-[590] text-[32px] mds:text-[48px] text-[#010140] ">
            {t("title")}
          </h3>

          <hr className="bottomborder h-[5px] mt-[1px] border-none rounded-[22.5px] w-[92px] mds:w-[138px] "></hr>
        </div>
      </div>
      {/* lower text  */}
      <div
        className="mx-auto mt-[4px] relative mds:mt-[-7px] col-span-12 text-center mds:text-[18px] leading-[28px] text-[#010140]"
        dangerouslySetInnerHTML={{ __html: t("description") }}
      />

      <div
        className="flex justify-center items-center mx-auto mt-7 cursor-pointer w-fit "
        onClick={() => {
          Router.reload(window.location.pathname);
        }}
      >
        <CustomBtnComponent txt={t("button")} />
      </div>
    </div>
  );
}

export default Error404;
